import { Button, ButtonGroup } from 'react-bootstrap';
import { useLoaderData, useNavigate } from 'react-router';
import { Project } from '../../utils/ProjectClient';
import { FileEarmarkRuled, PencilSquare, Trash } from 'react-bootstrap-icons';
import { useSubmit } from 'react-router-dom';
import jszip from 'jszip';
import * as bootstrap from 'bootstrap';
import DataTable from 'datatables.net-react';
import DataTablesCore, { ConfigColumns } from 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';
import 'datatables.net-fixedheader-bs5';
import 'datatables.net-keytable-bs5';
import 'datatables.net-searchpanes-bs5';

DataTablesCore.use(bootstrap);
DataTablesCore.Buttons.jszip(jszip);
DataTable.use(DataTablesCore);

export function ProjectBrowserTable() {
  const projects = useLoaderData() as Project[];
  const navigate = useNavigate();
  const submit = useSubmit();

  const columns: ConfigColumns[] = [
    { data: 'name', title: 'Name' },
    { data: 'owner', title: 'Owner' },
    { data: 'classification', title: 'Classification' },
    { data: 'usageType', title: 'Usage Type' },
    { data: 'borderType', title: 'Border Type' },
    { data: 'securityType', title: 'Security Type' },
    { data: 'waterOwner', title: 'Water Owner' },
    { data: 'waterOwnerContactInfo', title: 'Water Owner Contact Info' },
    { data: 'waterOwnerType', title: 'Water Owner Type' },
    { data: 'waterbodyType', title: 'Waterbody Type' },
    { title: 'Report' },
    { title: 'Edit' },
    { title: 'Delete' },
  ];

  // See https://datatables.net/manual/react
  return (
    <div className='mt-2'>
      <ButtonGroup>
        <Button variant='primary' onClick={() => navigate('/projects/new')} className='mb-2'>New</Button>
        <Button variant='secondary' onClick={() => navigate('/projects')} className='mb-2'>Map View</Button>
      </ButtonGroup>
      <DataTable
        className='table table-striped'
        columns={columns} 
        data={projects} 
        options={{
          fixedHeader: true,
          keys: true,
          searching: true,
          ordering: true,
          buttons: ['copy', 'csv', 'excel', 'print'],
        }}
        slots={{
          10: (data: String, row: any) => (
            <FileEarmarkRuled title='Report' onClick={(e) => navigate('/projects/' + row.id + '/report') } />
          ),
          11: (data: String, row: any) => (
            <PencilSquare title='Edit' onClick={(e) => navigate('/projects/' + row.id + '/edit') } />
          ),
          12: (data: String, row: any) => (
            <Trash title='Delete' onClick={(e) => {
              if (window.confirm('Are you sure you want to delete project "' + row.name + '"?')) {
                submit(null, {
                  method: 'delete',
                  action: '/projects/' + row.id,
                })
              }
            }} />
          ),
        }}>
      </DataTable>
    </div>
  );
}
